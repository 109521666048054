import React from 'react';
import { Helmet } from 'react-helmet';
import './term.css';
import Header from '../Header';

export default function TermsofService() {
    return (
        <div>
            <Helmet>
                <title>Terms of Service - Biodata Makers</title>
                <meta name="description"
                    content="BiodataMakers is your ultimate solution for creating professional, customizable biodata online for both job applications and marriage purposes. With an extensive library of beautifully designed templates, our platform simplifies the process of crafting a standout biodata that leaves a lasting impression. Whether you’re a job seeker aiming to showcase your qualifications or preparing a marriage biodata to highlight your personality and values, BiodataMakers has got you covered. Enjoy seamless customization, instant PDF downloads, and easy sharing options—all tailored to meet your specific needs. Take the hassle out of biodata creation and start your journey toward success with BiodataMakers today." />
                <meta
                    name="keywords"
                    content="biodata maker, online biodata creator, professional biodata templates, marriage biodata generator, job biodata format, biodata samples PDF, easy biodata creation, custom biodata maker, free biodata templates, biodata builder, resume generator, CV templates, biodata download online, marriage biodata format, create biodata online free, job biodata templates, professional biodata examples, biodata for marriage PDF, best biodata generator, biodata formats and designs"
                />
                <link rel="canonical" href="https://www.biodatamakers.com/term" />
            </Helmet>
            <Header />
            <div style={{ fontSize: "30px", fontWeight: "bold", textAlign: "center", marginTop: "30px" }}>Terms of Service</div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "30px", flexWrap: "wrap" }}>
                <div className='detailstape'>
                    <p className='detail'>Acceptance of Terms</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>By accessing and using Biodata Makers, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please refrain from using the Service.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Description of Service</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Biodata Makers provides a platform for creating biodata and related services. Users may create, edit, and download biodata templates for personal and professional use.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>User Accounts</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Users must provide accurate and complete information when creating an account. Users are responsible for maintaining the security of their account credentials. Biodata Makers reserves the right to suspend or terminate accounts that violate these terms or engage in unlawful activities.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Limitation of Liability</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Biodata Makers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "30px", flexWrap: "wrap" }}>
                <div className='detailstape'>
                    <p className='detail'>Intellectual Property</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Users retain ownership of their content but grant Biodata Makers a license to use, modify, and distribute the content for the purpose of providing the Service. Biodata Makers's trademarks, logos, and service marks are the property of Biodata Makers and may not be used without permission.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Termination</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Biodata Makers reserves the right to terminate or suspend access to the Service at its discretion, without prior notice, for users who violate these terms.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Disclaimer of Warranties</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>The Service is provided "as is" without warranties of any kind, either expressed or implied. Biodata Makers does not warrant the accuracy, completeness, or reliability of the Service.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'> Changes to Terms</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>Biodata Makers reserves the right to modify these Terms of Service at any time. Users are responsible for regularly reviewing the terms. Continued use of the Service after changes constitutes acceptance of the modified terms.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
