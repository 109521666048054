import React, { useState, useEffect } from "react";
import template1 from "../../assets/templates/1.jpeg";
import template2 from "../../assets/templates/99.jpeg";
import template3 from "../../assets/templates/100.jpeg";
import template4 from "../../assets/templates/101.jpeg";
import template5 from "../../assets/templates/5.jpeg";
import template10 from "../../assets/templates/10.jpeg";
import template12 from "../../assets/templates/12.jpeg";
import template13 from "../../assets/templates/13.jpeg";
import template14 from "../../assets/templates/14.jpeg";
import template16 from "../../assets/templates/16.jpeg";
import template19 from "../../assets/templates/19.jpeg";
import template25 from "../../assets/templates/25.jpeg";
import template26 from "../../assets/templates/26.jpeg";
import template30 from "../../assets/templates/30.jpeg";
import template31 from "../../assets/templates/31.jpeg";
import template32 from "../../assets/templates/32.jpeg";
import template33 from "../../assets/templates/33.jpeg";
import template35 from "../../assets/templates/35.jpeg";
import template45 from "../../assets/templates/45.webp";
import template46 from "../../assets/templates/46.jpg";
import template48 from "../../assets/templates/48.webp";
import template49 from "../../assets/templates/49.jpg";
import template51 from "../../assets/templates/51.webp";
import template52 from "../../assets/templates/52.webp";
import template101 from "../../assets/newdesign/101.jpg";
import template102 from "../../assets/newdesign/102.jpg";
import template103 from "../../assets/newdesign/103.jpg";
import template104 from "../../assets/newdesign/104.jpg";
import template105 from "../../assets/newdesign/105.jpg";
import template106 from "../../assets/newdesign/106.jpg";
import template107 from "../../assets/newdesign/107.jpg";
import template108 from "../../assets/newdesign/108.jpg";
import template110 from "../../assets/newdesign/110.jpg";
import template111 from "../../assets/newdesign/111.jpg";
import template112 from "../../assets/newdesign/112.jpg";
import template113 from "../../assets/newdesign/113.jpg";
import template114 from "../../assets/newdesign/114.jpg";
import template115 from "../../assets/newdesign/115.jpg";
import template116 from "../../assets/newdesign/116.jpg";
import template117 from "../../assets/newdesign/117.jpg";
import template118 from "../../assets/newdesign/118.jpg";
import template119 from "../../assets/newdesign/119.jpg";
import template121 from "../../assets/newdesign/121.jpg";
import template122 from "../../assets/newdesign/122.jpg";
import template123 from "../../assets/newdesign/123.jpg";
import template124 from "../../assets/newdesign/124.jpg";
import template125 from "../../assets/newdesign/125.jpg";
import template126 from "../../assets/newdesign/126.jpg";
import template127 from "../../assets/newdesign/127.jpg";
import template128 from "../../assets/newdesign/128.jpg";
import template129 from "../../assets/newdesign/129.jpg";
import template130 from "../../assets/newdesign/130.jpg";
import template131 from "../../assets/newdesign/131.jpg";
import template132 from "../../assets/newdesign/132.jpg";
import template133 from "../../assets/newdesign/133.jpg";
import template134 from "../../assets/newdesign/134.jpg";
import template135 from "../../assets/newdesign/135.jpg";
import template136 from "../../assets/newdesign/136.jpg";
import template137 from "../../assets/newdesign/137.jpg";
import template138 from "../../assets/newdesign/138.jpg";
import template139 from "../../assets/newdesign/139.jpg";
import template140 from "../../assets/newdesign/140.jpg";
import template141 from "../../assets/newdesign/141.jpg";
import template201 from "../../assets/newdesign/201.avif";
import template202 from "../../assets/newdesign/202.avif";
import template203 from "../../assets/newdesign/203.avif";
import template204 from "../../assets/newdesign/204.avif";
import template205 from "../../assets/newdesign/205.avif";
import template206 from "../../assets/newdesign/206.jpg";
import template207 from "../../assets/newdesign/207.jpg";
import template208 from "../../assets/newdesign/208.jpg";
import template210 from "../../assets/newdesign/210.jpg";
import template211 from "../../assets/newdesign/211.jpg";
import template212 from "../../assets/newdesign/212.jpg";
import template213 from "../../assets/newdesign/213.jpg";
import template216 from "../../assets/newdesign/216.jpg";
import template217 from "../../assets/newdesign/217.jpg";
import template218 from "../../assets/newdesign/218.jpg";
import template219 from "../../assets/newdesign/219.jpg";
import template220 from "../../assets/newdesign/220.jpg";
import template221 from "../../assets/newdesign/221.jpg";
import template222 from "../../assets/newdesign/222.jpg";
import template223 from "../../assets/newdesign/223.jpg";
import template224 from "../../assets/newdesign/224.jpg";
import template225 from "../../assets/newdesign/225.jpg";
import template226 from "../../assets/newdesign/226.jpg";
import template228 from "../../assets/newdesign/228.jpg";
import template229 from "../../assets/newdesign/229.jpg";
import template231 from "../../assets/newdesign/231.jpg";
import template232 from "../../assets/newdesign/232.jpg";
import template233 from "../../assets/newdesign/233.jpg";
import template234 from "../../assets/newdesign/234.jpg";
import template235 from "../../assets/newdesign/235.jpg";
import template236 from "../../assets/newdesign/236.jpg";
import template237 from "../../assets/newdesign/237.jpg";
import template238 from "../../assets/newdesign/238.jpg";
import template239 from "../../assets/newdesign/239.jpg";
import template240 from "../../assets/newdesign/240.jpg";
import template241 from "../../assets/newdesign/241.jpg";
import template242 from "../../assets/newdesign/242.jpg";
import template243 from "../../assets/newdesign/243.jpg";
import template244 from "../../assets/newdesign/244.jpg";
import template245 from "../../assets/newdesign/245.jpg";
import template246 from "../../assets/newdesign/246.jpg";
import template247 from "../../assets/newdesign/247.jpg";
import template248 from "../../assets/newdesign/248.jpg";
import template249 from "../../assets/newdesign/249.jpg";
import template250 from "../../assets/newdesign/250.jpg";
import template251 from "../../assets/newdesign/251.jpg";
import template252 from "../../assets/newdesign/252.jpg";
import template253 from "../../assets/newdesign/253.jpg";
import template254 from "../../assets/newdesign/254.jpg";
import template255 from "../../assets/newdesign/255.jpg";
import template256 from "../../assets/newdesign/256.jpg";
import template257 from "../../assets/newdesign/257.jpg";
import template258 from "../../assets/newdesign/258.jpg";
import template259 from "../../assets/newdesign/259.jpg";
import template260 from "../../assets/newdesign/260.jpg";
import template261 from "../../assets/newdesign/261.jpg";
import template262 from "../../assets/newdesign/262.jpg";
import template263 from "../../assets/newdesign/263.jpg";
import template264 from "../../assets/newdesign/264.jpg";
import template265 from "../../assets/newdesign/265.jpg";
import template266 from "../../assets/newdesign/266.jpg";
import template267 from "../../assets/newdesign/267.jpg";
import template268 from "../../assets/newdesign/268.jpg";
import template269 from "../../assets/newdesign/269.jpg";
import template270 from "../../assets/newdesign/270.jpg";
import template271 from "../../assets/newdesign/271.jpg";
import template272 from "../../assets/newdesign/272.jpg";
import template273 from "../../assets/newdesign/273.jpg";
import template274 from "../../assets/newdesign/274.jpg";
import template275 from "../../assets/newdesign/275.jpg";
import template276 from "../../assets/newdesign/276.jpg";
import template277 from "../../assets/newdesign/277.jpg";
import template278 from "../../assets/newdesign/278.jpg";
import template279 from "../../assets/newdesign/279.jpg";
import template280 from "../../assets/newdesign/280.jpg";
import template281 from "../../assets/newdesign/281.jpg";
import sample1 from "../../assets/sampleimage/Screenshot 2023-12-09 103109.png";
import sample2 from "../../assets/sampleimage/1.png";
import sample3 from "../../assets/sampleimage/Screenshot 2023-12-09 103222.png";
import jsPDF from "jspdf";
import { Link, useNavigate } from "react-router-dom";
import "./Templet.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import logo from "./image/8.jpg"
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import ModalComponent from "./Modal/modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
};
const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
};

export default function Demotemplate() {
    let alldata = [
        template1,
        template5,
        template10,
        template12,
        template13,
        template14,
        template16,
        template19,
        template25,
        template26,
        template30,
        template31,
        template32,
        template33,
        template35,
        template45,
        template46,
        template48,
        template49,
        template51,
        template52,
        template2,
        template3,
        template4,
        template101,
        template102,
        template103,
        template104,
        template105,
        template106,
        template107,
        template108,
        template110,
        template111,
        template112,
        template113,
        template114,
        template115,
        template116,
        template117,
        template118,
        template119,
        template121,
        template122,
        template123,
        template124,
        template125,
        template126,
        template127,
        template128,
        template129,
        template130,
        template131,
        template132,
        template133,
        template134,
        template135,
        template136,
        template137,
        template138,
        template139,
        template140,
        template141,
        template201,
        template202,
        template203,
        template204,
        template205,
        template206,
        template207,
        template208,
        template210,
        template211,
        template212,
        template213,
        template216,
        template217,
        template218,
        template219,
        template220,
        template221,
        template222,
        template223,
        template224,
        template225,
        template226,
        template228,
        template229,
        template231,
        template232,
        template233,
        template234,
        template235,
        template236,
        template237,
        template238,
        template239,
        template240,
        template241,
        template242,
        template243,
        template244,
        template245,
        template246,
        template247,
        template248,
        template249,
        template250,
        template251,
        template252,
        template253,
        template254,
        template255,
        template256,
        template257,
        template258,
        template259,
        template260,
        template261,
        template262,
        template263,
        template264,
        template265,
        template266,
        template267,
        template268,
        template269,
        template270,
        template271,
        template272,
        template273,
        template274,
        template275,
        template276,
        template277,
        template278,
        template279,
        template280,
        template281,
    ];



    return (
        <div>
            <Helmet>
                <title>Create Your Marriage Biodata | BiodataMakers</title>
                <meta name="description"
                    content="BiodataMakers is your ultimate solution for creating professional, customizable biodata online for both job applications and marriage purposes. With an extensive library of beautifully designed templates, our platform simplifies the process of crafting a standout biodata that leaves a lasting impression. Whether you’re a job seeker aiming to showcase your qualifications or preparing a marriage biodata to highlight your personality and values, BiodataMakers has got you covered. Enjoy seamless customization, instant PDF downloads, and easy sharing options—all tailored to meet your specific needs. Take the hassle out of biodata creation and start your journey toward success with BiodataMakers today." />
                <meta
                    name="keywords"
                    content="biodata maker, online biodata creator, professional biodata templates, marriage biodata generator, job biodata format, biodata samples PDF, easy biodata creation, custom biodata maker, free biodata templates, biodata builder, resume generator, CV templates, biodata download online, marriage biodata format, create biodata online free, job biodata templates, professional biodata examples, biodata for marriage PDF, best biodata generator, biodata formats and designs"
                />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="BiodataMakers" />
                <link rel="canonical" href="https://www.biodatamakers.com/biodata-make" />
                <meta property="og:title" content="Create Your Marriage Biodata | BiodataMakers" />
                <meta
                    property="og:description"
                    content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
                />
                <meta property="og:url" content="https://www.biodatamakers.com/biodata-make" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Create Your Marriage Biodata | BiodataMakers" />
                <meta
                    name="twitter:description"
                    content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
                />
                <meta
                    name="twitter:image"
                    content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
                />
            </Helmet>

            <Header />
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: "100px" }}>
                    {alldata.map((img, index) => (
                        <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                            <div style={{
                                height: '310px',
                                width: '260px',
                                margin: "10px",
                                display: "flex",
                                flexDirection: "column", // Added to stack image and name tag vertically
                                alignItems: "center" // Added to center align items
                            }} className='boxshadow'>
                                <img
                                    style={{
                                        height: '290px',
                                        width: '240px',
                                        cursor: 'pointer',
                                        margin: "10px",
                                        borderRadius: "10px"
                                    }}
                                    src={img}
                                    alt="biodata maker"
                                />
                                <div style={{ fontSize: '17px', fontWeight: 'bold', position: "absolute", top: "45%" }}>
                                    Sample Image
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div style={{ height: "50px", textAlign: "center", color: "black", paddingTop: "10px", fontFamily: "cursive", position: window.innerWidth >= "800" ? "relative" : "", bottom: "0", width: "100%" }}>
                Beautifully Handcrafted Marriage Biodata
            </div>
        </div>
    )
}
