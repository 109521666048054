import { useEffect } from "react";
import "../../assets/css/responsive.css";
import "../../assets/css/style.css";
import Aos from "aos";
import Slideshow from "./Slideshow";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

function Home() {
  const naviagte = useNavigate();
  localStorage.clear();

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>BiodataMakers - Create Your Professional Biodata Online</title>
        <meta name="description" content="Easily create your professional marriage biodata online with BiodataMakers. Customize templates, download in PDF format, and get started with your marriage or job search." />
        <meta name="keywords" content="biodata maker, online biodata creator, professional biodata templates, marriage biodata generator, job biodata format, biodata samples PDF, easy biodata creation, custom biodata maker, free biodata templates, biodata builder, resume generator, CV templates, biodata download online, marriage biodata format, create biodata online free, job biodata templates, professional biodata examples, biodata for marriage PDF, best biodata generator, biodata formats and designs" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="BiodataMakers" />
        <link rel="canonical" href="https://www.biodatamakers.com" />
        <meta property="og:title" content="BiodataMakers - Create Your Professional Biodata Online" />
        <meta property="og:description" content="Easily create your professional marriage biodata online with BiodataMakers. Customize templates, download in PDF format, and get started with your marriage or job search." />
        <meta property="og:url" content="https://www.biodatamakers.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.biodatamakers.com/assets/images/hero_img.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="BiodataMakers - Create Your Professional Biodata Online" />
        <meta name="twitter:description" content="Easily create your professional marriage biodata online with BiodataMakers. Customize templates, download in PDF format, and get started with your marriage or job search." />
        <meta name="twitter:image" content="https://www.biodatamakers.com/assets/images/hero_img.png" />
      </Helmet>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <Header />
      <Header />


      <section id="hero">
        <div className="container">
          <div className="hero_wrap">
            <div className="hero_text">
              <h1 className="hero_title">
                Easy Online Marriage
                <span className="hero_title_primary">
                  Bi<span className="hero_title_primary_inner">o</span>data
                </span>
                <br></br>
                Maker
              </h1>
              <p className="hero_inner_text">
                Simply click a few buttons to create an exquisite biodata for
                marriage! Simple to use, completely adjustable, beautifully
                styled marriage biodata formats
              </p>
              <button
                onClick={() => naviagte("biodata-make")}
                className="cta hero_button"
              >
                Create Biodata Card
                <span className="icon">
                  <i className="fa-solid fa-chevron-right first"></i>
                  <i className="fa-solid fa-chevron-right last"></i>
                </span>
              </button>
              {/* <!-- <button>Create Biodata Card</button> --> */}
            </div>
            <div className="hero_img">
              <img
                className="hero_inner_img"
                src="assets/images/hero_img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero Section --> */}

      <section className="trending_temp">
        <div className="container justify-content-center">
          <div className="trending">
            <h2 className="trending_title">Trending Templates</h2>
            <p className="trending_sub_title">
              Remember, your marriage biodata serves as your introduction, so
              keep it as authentic as you are!
            </p>
            <div className="row">
              <div className="col-12">
                <div
                  className="kafelki_container aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <div className="kafelki_1">
                    <div className="kafelki_item_1">
                      <div className="box tech-savvy">Tech Savvy</div>
                    </div>
                  </div>
                  <div className="kafelki_2">
                    <div className="kafelki_item_1">
                      <div className="box dradient-delight">
                        Gradient Delight
                      </div>
                    </div>
                  </div>
                  <div className="kafelki_3">
                    <div className="kafelki_item_1">
                      <div className="box nature-inspired">
                        Nature- Inspired
                      </div>
                    </div>
                  </div>
                  <div className="kafelki_4">
                    <div className="kafelki_item_1">
                      <div className="box futuristic-vibes">
                        Futuristic Vibes
                      </div>
                    </div>
                  </div>
                  <div className="kafelki_5">
                    <div className="kafelki_item_1">
                      <div className="box bold-typography">
                        Bold Typography
                      </div>
                    </div>
                  </div>
                  <div className="kafelki_6">
                    <div className="kafelki_item_1">
                      <div className="box vintage-charm">Vintage Charm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how_work">
        <div className="container">
          <div className="how_title">
            <h2 className="how_it_title">HOW IT WORKS</h2>
            <img
              src="assets/images/how_work_line.png"
              alt=""
              className="inline-flex"
            />
          </div>
          <p className="how_it_inner_text">
            Simple Steps to Make Your Marriage Bio-Data
          </p>
          <div className="flex justify-center items-center w-full">
            <img src="assets/images/how_work.png" alt="" />
          </div>
        </div>
      </section>

      <section className="premium_temp">
        <div className="container">
          <h2>Premium Templates</h2>
          <p>
            Highlight important details such as education, career, and family
            background.
          </p>



          <div
            style={{
              // opacity: "0.7",
              // background: "#EFEEF3",
              // paddingTop: "50px",
              paddingBottom: "50px",
              // marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            id="Templet"
          >
            <Slideshow />
          </div>
          <div className="row align-items-center">
            <div className="d-flex justify-content-center"
              onClick={() => naviagte("biodata-make")}
            >
              <a className="cta hero_button">
                Create Biodata Card
                <span className="icon">
                  <i className="fa-solid fa-chevron-right first"></i>
                  <i className="fa-solid fa-chevron-right last"></i>
                </span>
              </a>
            </div>

          </div>
        </div>
      </section>

      <section className="choose_by">
        <div className="container">
          <h2 className="choose_by_title">Choose By Category</h2>
          <p className="choose_by_inner_text">100+ Free Category</p>
          <div className="category_template">
            <div className="bu_template">
              <img src="assets/images/BusinessTemplate.png" alt="" />
              <h3 className="bu_title">100+</h3>
              <p className="bu_inner_text">Business Template</p>
            </div>
            <div className="bu_template">
              <img src="assets/images/JobTemplate.png" alt="" />
              <h3 className="bu_title">100+</h3>
              <p className="bu_inner_text">Job Template</p>
            </div>
            <div className="bu_template">
              <img src="assets/images/UnmarriedTemplate.png" alt="" />
              <h3 className="bu_title">100+</h3>
              <p className="bu_inner_text">Unmarried Template</p>
            </div>
            <div className="bu_template">
              <img src="assets/images/ForeignTemplate.png" alt="" />
              <h3 className="bu_title">100+</h3>
              <p className="bu_inner_text">Foreign Template</p>
            </div>
          </div>
        </div>
      </section>

      <section className="blog_area">
        <div className="container">
          <h2 className="blog_title">BLOG</h2>
          <h4 className="blog_inner_title">Success Stories & Use Cases</h4>
          <p className="blog_text">
            Building a biodata-making website involves several steps, from
            planning and design to development and deployment. Here's a
            detailed guide to help you create a customizable biodata maker web
            application
          </p>
          <div className="blog_border">
            <div className="blog_background_img">
              <div className="blogsubscribe">
                <div className="blog_subscribe_text">
                  <h4>NEWS LATTERS</h4>
                  <h3>Subscribe Newsletters</h3>
                  <p style={{ color: "white" }}>
                    Subscribe to our newsletters right now and get Special
                    offers
                  </p>
                </div>
                <div className="blog_input">
                  <div className="searchbox-wrap">
                    <input type="email" placeholder="Enter Your Email" />
                    <button style={{ textAlign: "center" }}>
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="site-footer">
        <div class="container">
          <div class="footer-content">
            <div class="footer-section about">
              <h3 class="section-title">About</h3>
              <p>
                Introducing the importance of a well-structured biodata PDF can make
                a significant positive impression on employers or others reviewing
                it. Remember to use a friendly and engaging tone throughout the blog
                post, and feel free to customize the content based on your target
                audience and the specific biodata creation tool you're recommending.
              </p>
            </div>
            <div style={{ marginBottom: "30px" }}>
              <div class="support">
                <h3 class="section-title">Support</h3>
                <ul class="link-list" >

                  <li>
                    <Link className="nav-link scrollto" to="/blog" style={{ color: "black" }}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/term" style={{ color: "black" }}>
                      Term
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/policy" style={{ color: "black" }}>
                      Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/template" style={{ color: "black" }} >
                      Template
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-section links">
              <div class="links-flex">
                <div class="contact">
                  <h3 class="section-title">Contact With Us</h3>
                  <ul class="contact-list">
                    <li>
                      <a href="mailto:biodatamakers1@gmail.com" class="contact-item">
                        <i class="fa-solid fa-envelope"></i>
                        <p class="contact-text" style={{ color: "black" }}>biodatamakers1@gmail.com</p>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.biodatamakers.com" class="contact-item">
                        <i class="fa-solid fa-globe"></i>
                        <p class="contact-text" style={{ color: "black" }}>www.biodatamakers.com</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* </Layout> */}
    </>
  );
}

export default Home;
