import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';

export default function PrivacyPolicy() {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Biodata Makers</title>
                <meta name="description"
                    content="BiodataMakers is your ultimate solution for creating professional, customizable biodata online for both job applications and marriage purposes. With an extensive library of beautifully designed templates, our platform simplifies the process of crafting a standout biodata that leaves a lasting impression. Whether you’re a job seeker aiming to showcase your qualifications or preparing a marriage biodata to highlight your personality and values, BiodataMakers has got you covered. Enjoy seamless customization, instant PDF downloads, and easy sharing options—all tailored to meet your specific needs. Take the hassle out of biodata creation and start your journey toward success with BiodataMakers today." />
                <meta
                    name="keywords"
                    content="privacy policy,create biodata, data collection, data usage, personal information, security, updates, communication"
                />
                <link rel="canonical" href="https://www.biodatamakers.com/policy" />
            </Helmet>
            <Header />
            <div style={{ fontSize: "30px", fontWeight: "bold", textAlign: "center", marginTop: "90px" }}>Privacy Policy</div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "30px", flexWrap: "wrap" }}>
                <div className='detailstape'>
                    <p className='detail'>Information We Collect</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>We may collect personal information, including but not limited to, your name, contact information, and any other details you voluntarily provide when using our biodata-making web service.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>How We Use Your Information</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>The primary purpose of collecting your information is to create biodata documents based on the details you provide. We may use aggregated and anonymized data for the improvement of our service, including website functionality and user experience.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Your Choices</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>You have the right to access, correct, or delete your personal information. You can manage your preferences through your account settings or by contacting us.</p>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "30px", flexWrap: "wrap" }}>
                <div className='detailstape'>
                    <p className='detail'>Security</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'> We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'>Changes to this Privacy Policy</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated.</p>
                    </div>
                </div>
                <div className='detailstape'>
                    <p className='detail'> Communication</p>
                    <div style={{ paddingTop: "10px" }}>
                        <p className='otherdetail'>We may use your contact information to send you updates, notifications, and important information related to our service.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
